import React, { useState, useContext } from "react";
import ContactsContext from "./ContactsContext";

export const useContactsContext = () => {
  return useContext(ContactsContext);
};

export const ContactsContextProvider = ({ children }) => {
  const [accountInfo, setAccountInfo] = useState({});
  const [accountNameInfo, setAccountNameInfo] = useState({});
  const [policyInfo, setPolicyInfo] = useState("");
  const [user, setUser] = useState(false);
  const [actionStartTime, setActionStartTime] = useState("");
  const [correlationId, setCorrelationId] = useState("");
  const [searchAlertVisible, setSearchAlertVisible] = useState(false);
  const [searchAlertType, setSearchAlertType] = useState("");
  const [searchAlertContent, setSearchAlertContent] = useState("");

  const updateAccountInfo = (accountInfo) => {
    setAccountInfo(accountInfo);
  };

  const updateAccountNameInfo = (accountNameInfo) => {
    setAccountNameInfo(accountNameInfo);
  };

  const updatePolicyInfo = (policyInfo) => {
    setPolicyInfo(policyInfo);
  };

  const updateUser = (user) => {
    setUser(user);
  };

  const updateActionStartTime = (actionStartTime) => {
    setActionStartTime(actionStartTime);
  };

  const updateCorrelationId = (correlationId) => {
    setCorrelationId(correlationId);
  };

  const updateSearchAlertVisible = (isAlertVisible) => {
    setSearchAlertVisible(isAlertVisible);
  };

  const updateSearchAlertType = (aleryType) => {
    setSearchAlertType(aleryType);
  };

  const updateSearchAlertContent = (alertContent) => {
    setSearchAlertContent(alertContent);
  };

  const clearAlertView = () => {
    updateSearchAlertVisible(false);
    updateSearchAlertType("");
    updateSearchAlertContent("");
  };

  const handleAlertView = (alertContent, alertType, alertVisible) => {
    updateSearchAlertVisible(alertVisible);
    updateSearchAlertType(alertType);
    updateSearchAlertContent(alertContent);

    if (alertContent !== "") {
      const enterActionId = window?.dtrum?.enterAction(
        `${alertContent}`,
        undefined,
        new Date().getTime(),
        window.location.href
      );
      window?.dtrum?.leaveAction(enterActionId, new Date().getTime());
    }
  };

  return (
    <ContactsContext.Provider
      value={{
        accountInfo,
        user,
        accountNameInfo,
        policyInfo,
        updateAccountNameInfo,
        updateAccountInfo,
        updatePolicyInfo,
        updateUser,
        actionStartTime,
        updateActionStartTime,
        correlationId,
        updateCorrelationId,
        searchAlertVisible,
        updateSearchAlertVisible,
        searchAlertType,
        updateSearchAlertType,
        searchAlertContent,
        updateSearchAlertContent,
        clearAlertView,
        handleAlertView,
      }}
    >
      {children}
    </ContactsContext.Provider>
  );
};
