const contactTypesLearnMore = [
    {
        contactType: "Primary Contact",
        definition: "Individual(s) at the company authorized to make decisions about their insurance coverage(s)",
        whatItMeans: "Receives new business and renewal experience communications; NPS relationship surveys, etc. Receives full access to MyTravelers®​"
    },
    {
        contactType: "Cyber Security",
        definition: "Individual(s) responsible for cyber security for the company",
        whatItMeans: "Receives cyber vulnerability alerts and similar information​"
    },
    {
        contactType: "Authorized Signer​",
        definition: "The individual(s) at the company authorized to sign legal documents on behalf of the company",
        whatItMeans: "Can use and sign documents via the DocuSign tool​"
    },
    {
        contactType: "Risk Management​",
        definition: "Individual(s) with an interest in managing, or a requirement to manage, risks for the company",
        whatItMeans: "Has access to RiskManagement.Travelers.com and receives risk management related communications​"
    }
]

const contactTypesMap = {
    "ALL": "All Contacts",
    "PC": "Primary Contact",
    "AS": "Authorized Signer",
    "RM": "Risk Management",
    "CB": "Cyber Security"
}

const contactTypesSelect = [
    {
        name: "All Contacts",
        value: "ALL"
    },
    {
        name: "Primary Contact",
        value: "PC"
    },
    {
        name: "Cyber Security",
        value: "CB"
    },
    {
        name: "Risk Management",
        value: "RM"
    },
    {
        name: "Authorized Signer",
        value: "AS"
    }
];

export { contactTypesLearnMore, contactTypesMap, contactTypesSelect };