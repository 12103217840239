import "@trv-tds/core/dist/css/foundations-with-grid.min.css";
import { TdsAlert } from "@trv-tds/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useContactsContext } from "../contextapi/ContactsContextProvider";
import SearchHelper from "./SearchHelper";
import "../assets/stylesheets/home.css";
import {
  fipcAccountNumberSearchRegex,
  plAccountNumberSearchRegex,
  policyNumberSearchRegex,
  accountNameSearchRegex,
} from "../helpers/regexHelper";

function Search({ fromHomePage }) {
  const contactsContext = useContactsContext();
  const [search, setSearch] = useState(``);
  const navigate = useNavigate();

  let alertContent;

  const updateSearch = (e) => {
    setSearch(e.target.value);
  };

  const submitSearch = async (e) => {
    const isAccountNumber =
      fipcAccountNumberSearchRegex.test(String(e.target.value)) ||
      plAccountNumberSearchRegex.test(String(e.target.value));
    const isPolicyNumber = policyNumberSearchRegex.test(String(e.target.value));
    const isAccountName = accountNameSearchRegex.test(String(e.target.value));

    if (!isAccountNumber && !isAccountName && !isPolicyNumber) {
      alertContent =
        "We are sorry, there are no accounts matching your request. Please check and/or modify your search criteria.";

      contactsContext.handleAlertView(alertContent, "error", true, false);
      return;
    }
    try {
      if (!contactsContext.user.isExternal && isAccountNumber) {
        // make request to internal get contacts by account number search
        accountNumberHandler(e.target.value);
        return;
      } else if (!contactsContext.user.isExternal && isPolicyNumber) {
        // make request to internal get contacts by policy number search
        policyNumberHandler(e.target.value);
        return;
      } else if (
        (!contactsContext.user.isExternal && isAccountName) ||
        contactsContext.user.isExternal
      ) {
        // make request to internal get accounts search OR to external get accounts search for agents
        accountNameHandler(e.target.value);
        return;
      }
    } catch (error) {
      alertContent =
        "We are sorry, something went wrong. Please try again later. We apologize for the inconvenience and thank you for your patience.";

      contactsContext.handleAlertView(alertContent, "error", true, false);
      return;
    }
  };

  const accountNumberHandler = (accountNumber) => {
    contactsContext.updateActionStartTime(new Date().getTime());

    contactsContext.clearAlertView();
    contactsContext.updatePolicyInfo("");

    navigate(`accounts/${accountNumber}/contacts`);
  };

  const accountNameHandler = (accountName) => {
    contactsContext.updateAccountNameInfo({
      searchedItem: search,
    });

    contactsContext.clearAlertView();
    contactsContext.updatePolicyInfo("");

    navigate(`/accounts?accountName=${accountName}`);
  };

  const policyNumberHandler = (policyNumber) => {
    contactsContext.updateActionStartTime(new Date().getTime());

    contactsContext.clearAlertView();
    contactsContext.updatePolicyInfo(policyNumber);

    navigate(`policies/${policyNumber}/contacts`);
  };

  return (
    <>
      {contactsContext.searchAlertVisible && (
        <div className="tds-row centered alert-div">
          <div
            className={
              fromHomePage ? "tds-col-sm-2 alert-style" : "tds-col-sm-1"
            }
          ></div>
          <div
            className={
              fromHomePage
                ? "tds-col-sm-8 account-search-container"
                : "tds-col-sm-10"
            }
            aria-live="polite"
          >
            <TdsAlert
              data-testid="form-alert"
              type={contactsContext.searchAlertType}
              inline="true"
              className="alert-message"
            >
              <span className="tds-sr-only">
                {contactsContext.searchAlertType}:{" "}
              </span>
              {contactsContext.searchAlertContent}
            </TdsAlert>
          </div>

          <div className="tds-col-sm-auto"></div>
        </div>
      )}

      <SearchHelper
        search={search}
        submitSearch={submitSearch}
        updateSearch={updateSearch}
        fromHomePage={fromHomePage}
      />
    </>
  );
}
export default Search;
