import {
  TdsAlert,
  TdsCard,
  TdsCardContent,
  TdsLoadingSpinner,
} from "@trv-tds/react";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../assets/stylesheets/createcontact.css";
import { contactFormAlertValidation } from "../helpers/formValidationsHelper";
import ContactForm from "../components/ContactForm.js";
import { useContactsContext } from "../contextapi/ContactsContextProvider.js";
import { passthroughToContactCollection } from "../services/PassthroughService";

const phoneFormatter = require("phone-formatter");

function CreateContact() {
  const contactsContext = useContactsContext();
  const { account } = useParams();
  const navigate = useNavigate();

  const [formAlertContent, setFormAlertContent] = useState("");
  const [formAlertType, setFormAlertType] = useState("");
  const [alertVisible, setAlertVisible] = useState(false);
  const [loadSpinnerVisible, setLoadSpinnerVisible] = useState(false);

  let alertContent;

  const handleResponseError = () => {
    alertContent =
      "We are sorry, something went wrong. Please try again later. We apologize for the inconvenience and thank you for your patience.";

    setFormAlertType("error");
    setFormAlertContent(alertContent);
    setAlertVisible(true);
  };

  async function handleSubmit(
    incomingEvent,
    textVal,
    checkVal,
    setFormInputMsgAlertType,
    setFormInputMsgValue
  ) {
    incomingEvent.preventDefault();
    const validationErrors = contactFormAlertValidation(textVal, checkVal);

    if (validationErrors.formAlert.isFormAlertVisible) {
      setFormInputMsgAlertType(validationErrors.formInputMsgAlertType);
      setFormInputMsgValue(validationErrors.formInputMsgValue);
      setFormAlertContent(validationErrors.formAlert.formAlertContent);
      setFormAlertType(validationErrors.formAlert.formAlertType);
      setAlertVisible(validationErrors.formAlert.isFormAlertVisible);
      alertContent = validationErrors.formAlert.formAlertContent;
      return;
    }

    try {
      const newContactRequest = {
        acct_nbr: contactsContext.accountInfo.acct_nbr,
        contact: {
          fst_nm: textVal.fst_nm,
          lst_nm: textVal.lst_nm,
          cust_cntc_cel_tel_nbr: textVal.cust_cntc_cel_tel_nbr
            ? phoneFormatter.format(textVal.cust_cntc_cel_tel_nbr, "NNNNNNNNNN")
            : "",
          email: textVal.email,
          cust_cntc_typ_cd: checkVal,
          crtd_datasrc_nm: "CUI",
          crtd_usr_full_nm: contactsContext.user.name,
        },
      };
      setLoadSpinnerVisible(true);

      const newContactResponse = await passthroughToContactCollection(
        "POST",
        "/api/v1/contact",
        contactsContext.user.isExternal,
        newContactRequest
      );

      const { data } = newContactResponse;

      if (data?.statusCode === 200 || data?.statusCode === 201) {
        const actionName =
          contactsContext.accountInfo.contacts &&
          contactsContext.accountInfo.contacts.length
            ? "Creating Contact"
            : "Creating First Contact";

        contactsContext.updateAccountInfo({
          ...contactsContext?.accountInfo,
          contacts: [
            ...(contactsContext.accountInfo.contacts ?? []),
            data?.body,
          ],
        });

        const enterActionId = window?.dtrum?.enterAction(
          actionName,
          undefined,
          contactsContext.actionStartTime,
          window.location.href
        );
        window?.dtrum?.leaveAction(enterActionId, new Date().getTime());
        const path = contactsContext.policyInfo
          ? `/policies/${contactsContext.policyInfo}/contacts`
          : `/accounts/${contactsContext.accountInfo.acct_nbr}/contacts`;
        navigate(path, {
          replace: true,
          state: { successMessage: "New contact has been added." },
        });
      } else if (
        newContactResponse?.data?.statusCode === 400 &&
        newContactResponse?.data?.body.errorText ===
          "Data Error - Duplicate contact exists"
      ) {
        alertContent = "Contact already exists. Unable to add.";

        setFormAlertType("error");
        setFormAlertContent(alertContent);
        setAlertVisible(true);
      } else {
        handleResponseError();
      }

      return;
    } catch (err) {
      handleResponseError();
      return;
    } finally {
      if (alertContent) {
        const enterActionId = window?.dtrum?.enterAction(
          `${alertContent}`,
          undefined,
          new Date().getTime(),
          window.location.href
        );
        window?.dtrum?.leaveAction(enterActionId, new Date().getTime());
      }
      setLoadSpinnerVisible(false);
    }
  }

  useEffect(() => {
    contactsContext.clearAlertView();
    document.title = `New Contact | ${contactsContext.accountInfo.acct_nbr} | Customer Contact Collection`;

    if (Object.keys(contactsContext.accountInfo).length === 0) {
      navigate(`/accounts/${account}/contacts`, { replace: true });
    }
  }, []);

  return (
    <div className="tds-container">
      <div className="alert-container">
        {(!contactsContext?.accountInfo?.contacts ||
          contactsContext.accountInfo.contacts.length === 0) && (
          <TdsAlert
            className="no-contacts-alert"
            type="error"
            dismissible={true}
          >
            There are no contacts for this account. Please add a new contact
            below.
          </TdsAlert>
        )}
        {alertVisible && (
          <TdsAlert
            className="form-alert"
            data-testid="form-alert"
            type={formAlertType}
            dismissible={true}
          >
            {" "}
            {formAlertContent}{" "}
          </TdsAlert>
        )}
      </div>
      <div className="tds-row search-card-row">
        <TdsCard className="add-contact-card" size="full">
          <div className="add-contact-card-main-text">
            <TdsCardContent className="add-contact-card-content">
              <div className="add-contact-card-search-text">
                <h1 className="tds-h1 add-contact-title">
                  Add Contact Information
                </h1>
                <h2 className="tds-h3 add-contact-title">
                  Account Number: {contactsContext.accountInfo.acct_nbr}{" "}
                  <br></br>{" "}
                  <span className="add-account-name-title">
                    {contactsContext.accountInfo.acct_nm}
                  </span>
                </h2>
              </div>
              <ContactForm
                setFormAlertContent={setFormAlertContent}
                setFormAlertType={setFormAlertType}
                setAlertVisible={setAlertVisible}
                handleSubmit={handleSubmit}
              />
            </TdsCardContent>
          </div>
        </TdsCard>
      </div>

      {loadSpinnerVisible && (
        <TdsLoadingSpinner
          size="x-large"
          label-displayed
          overlay
          centered
        ></TdsLoadingSpinner>
      )}
    </div>
  );
}

export default CreateContact;
