import React from "react";
import "./index.css";
import {
  createBrowserRouter,
  defer,
  RouterProvider,
  redirect,
  Outlet,
} from "react-router-dom";
import queryClient from "./queryClient";
import { LoginCallback } from "@okta/okta-react";
import Home from "./pages/Home";
import AccountNameSelect from "./pages/AccountNameSelect";
import SearchResults from "./pages/SearchResults";
import CreateContact from "./pages/CreateContact";
import EditContact from "./pages/EditContact";
import LoginError from "./components/LoginError/LoginError";
import {
  WildcardProtectedRoute,
  ReadOnlyProtectedRoute,
  EditProtectedRoute,
} from "./components/ProtectedRoute";
import { RequiredAuth } from "./SecureRoute";
import { passthroughToContactCollection } from "./services/PassthroughService";
import App from "./App";
import {
  fipcAccountNumberSearchRegex,
  plAccountNumberSearchRegex,
  policyNumberSearchRegex,
  accountNameSearchRegex,
} from "../src/helpers/regexHelper";

const host = window.location.hostname.toLowerCase();
const IsExternal = host.includes("customercontacts");

let allowAccountNumberSearch = false;

const setAllowAccountNumberSearchLoader = async () => {
  setAllowAccountNumberSearch(true);
  return null;
};

const protectedLoader = async () => {
  if (IsExternal) {
    if (!allowAccountNumberSearch) {
      throw redirect("/");
    }
  }
  setAllowAccountNumberSearch(false);
  return null; // You can also return deferred data here if needed
};

const getAlertResponse = async (alertContent) => {
  return {
    data: {
      body: alertContent,
      invalidInput: true,
    },
  };
};

const setAllowAccountNumberSearch = (toggle) => {
  allowAccountNumberSearch = toggle;
  return null;
};

const getAccountNameResponse = async (accountName) => {
  const cachedData = queryClient.getQueryData(accountName);
  if (cachedData) {
    return cachedData;
  }

  let userId = JSON.parse(window.localStorage.getItem("okta-token-storage"))
    .idToken.claims.preferred_username;

  const requestType = !IsExternal ? "GET" : "POST";

  const route = !IsExternal
    ? `/api/v1/accountName?accountName=${encodeURIComponent(accountName)}`
    : "/api/v1/agentAccounts";

  const isAccountNumber =
    fipcAccountNumberSearchRegex.test(String(accountName)) ||
    plAccountNumberSearchRegex.test(String(accountName));
  const isAccountName = accountNameSearchRegex.test(String(accountName));

  const requestData = !IsExternal
    ? null
    : {
        accountNumber: isAccountNumber ? accountName : null,
        accountName: isAccountName && !isAccountNumber ? accountName : null,
        userId: userId,
      };

  const alertContent =
    "We are sorry, there are no accounts matching your request. Please check and/or modify your search criteria.";
  if (!isAccountName && !isAccountNumber && IsExternal) {
    return getAlertResponse(alertContent);
  } else if (!isAccountName && !IsExternal) {
    return getAlertResponse(alertContent);
  } else {
    const response = passthroughToContactCollection(
      requestType,
      route,
      IsExternal,
      requestData,
      "N"
    );
    setAllowAccountNumberSearch(true);
    return response;
  }
};

const getAccountNumberResponse = async (accountNumber) => {
  const isAccountNumber =
    fipcAccountNumberSearchRegex.test(String(accountNumber)) ||
    plAccountNumberSearchRegex.test(String(accountNumber));

  if (!isAccountNumber) {
    const alertContent =
      "We are sorry, there are no accounts matching your request. Please check and/or modify your search criteria.";

    return getAlertResponse(alertContent);
  } else {
    const polarisSkip = allowAccountNumberSearch ? "Y" : "N";
    return passthroughToContactCollection(
      "GET",
      `/api/v1/accountNumber/${accountNumber}`,
      IsExternal,
      null,
      polarisSkip
    );
  }
};

const getPolicyNumberResponse = async (policyNumber) => {
  const isPolicyNumber = policyNumberSearchRegex.test(String(policyNumber));

  if (!isPolicyNumber) {
    const alertContent =
      "We are sorry, there are no accounts matching your request. Please check and/or modify your search criteria.";

    return getAlertResponse(alertContent);
  } else {
    return passthroughToContactCollection(
      "GET",
      `/api/v1/policyNumber/${policyNumber}`,
      IsExternal,
      null
    );
  }
};

export const router = createBrowserRouter([
  {
    element: <App isExternal={IsExternal} />,
    children: [
      {
        path: "/login/callback",
        element: <LoginCallback errorComponent={LoginError} />,
      },
      {
        path: "/",
        element: <RequiredAuth />,
        children: [
          {
            index: true,
            element: <Home />,
          },
          {
            path: "accounts",
            element: <AccountNameSelect />,
            loader: async ({ request }) => {
              const url = new URL(request.url);
              const accountName = url.searchParams.get("accountName");
              return defer({ promise: getAccountNameResponse(accountName) });
            },
          },
          {
            element: <Outlet />, // Simplified ProtectedRoute
            loader: protectedLoader, // Run the authentication check here
            children: [
              {
                path: "accounts/:account/contacts",
                element: <SearchResults />,
                loader: async ({ params }) => {
                  return defer({
                    contacts: getAccountNumberResponse(params.account),
                  }); // should be called 'promise' instead of 'contacts'
                },
              },
            ],
          },
          {
            element: <Outlet />, // Simplified ProtectedRoute
            loader: protectedLoader, // Run the authentication check here
            children: [
              {
                path: "policies/:policy/contacts",
                element: <SearchResults />,
                loader: async ({ params }) => {
                  return defer({
                    contacts: getPolicyNumberResponse(params.policy),
                  });
                },
              },
            ],
          },
          {
            element: <ReadOnlyProtectedRoute />,
            children: [
              {
                path: "accounts/:account/contacts/add",
                element: <CreateContact />,
                loader: setAllowAccountNumberSearchLoader,
              },
              {
                element: <EditProtectedRoute />,
                children: [
                  {
                    path: "accounts/:account/contacts/edit",
                    element: <EditContact />,
                    loader: setAllowAccountNumberSearchLoader,
                  },
                ],
              },
            ],
          },
          {
            path: "*",
            element: <WildcardProtectedRoute />,
          },
        ],
      },
    ],
  },
]);
