import { useState, useEffect, Suspense } from "react";
import {
  TdsAlert,
  TdsModal,
  TdsModalButtons,
  TdsButton,
  TdsLoadingSpinner,
} from "@trv-tds/react";
import "../assets/stylesheets/searchResults.css";
import { useContactsContext } from "../contextapi/ContactsContextProvider.js";
import ContactsDataTable from "../components/ContactsDataTable.js";
import {
  useNavigate,
  useLocation,
  useLoaderData,
  Await,
  useParams,
} from "react-router-dom";
import {
  contactNeedsAttentionStatuses,
  verifiedContact,
} from "../helpers/contactStatusesHelper.js";
import { passthroughToContactCollection } from "../services/PassthroughService";
import { contactTransformationHelper } from "../helpers/contactTransformationHelper.js";
import Home from "./Home.js";

function SearchResults() {
  const contactsContext = useContactsContext();
  const { account } = useParams();
  const loaderData = useLoaderData();
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    contactsContext.clearAlertView();
    loaderData?.contacts
      ?.then((contacts) => {
        let { data } = contacts;
        if (data.invalidInput) {
          contactsContext.handleAlertView(data.body, "error", true);
          navigate("/", { replace: true });
        }
        if (
          data.statusCode === 200 &&
          data?.message === "Account found in Data Store"
        ) {
          contactsContext.updateAccountInfo(data.body);
        } else if (data.statusCode === 404) {
          contactsContext.handleAlertView(
            "We are sorry, there are no accounts matching your request. Please check and/or modify your search criteria.",
            "error",
            true
          );
          navigate("/", { replace: true });
        } else if (
          data.statusCode === 200 &&
          (data?.message === "Account Found in PolarisDB" ||
            data?.message === "Account Found in MDM")
        ) {
          if (
            contactsContext.user.is_read_only ||
            contactsContext.user.is_read_only === undefined
          ) {
            contactsContext.handleAlertView(
              "There are no contacts available for this account.",
              "error",
              true
            );
          }
          data = contactTransformationHelper(data);
          contactsContext.updateAccountInfo(data.body);
          navigate(`/accounts/${data.body.acct_nbr}/contacts/add`, {
            replace: true,
          });
        } else if (data.statusCode === 500) {
          contactsContext.handleAlertView(
            "We are sorry, something went wrong. Please try again later. We apologize for the inconvenience and thank you for your patience.",
            "error",
            true
          );
          navigate("/", { replace: true });
        }
      })
      .catch((error) => {
        contactsContext.handleAlertView(
          "We are sorry, something went wrong. Please try again later. We apologize for the inconvenience and thank you for your patience.",
          "error",
          true
        );
        navigate("/", { replace: true });
      });
    const searchedItem = contactsContext.policyInfo || account;
    document.title = `${searchedItem} | Customer Contact Collection`;
  }, []);

  const searchActionName = contactsContext.user.is_read_only
    ? "Search by Read-Only User"
    : "Search by Write User";
  const enterActionId = window?.dtrum?.enterAction(
    searchActionName,
    undefined,
    new Date().getTime(),
    window.location.href
  );
  window?.dtrum?.leaveAction(enterActionId, new Date().getTime());

  const [contactSuccessMessage, setSuccessMessage] = useState(
    state?.successMessage
  );
  const contactDeleteErrorMessage =
    "We are sorry, something went wrong. Please try again later. We apologize for the inconvenience and thank you for your patience.";
  const noPrimaryContactMessage =
    "This account does not have a primary contact.";
  const contactRequiresAttentionMessage = "A contact requires attention.";

  const [loadSpinnerVisible, setLoadSpinnerVisible] = useState(false);
  const [contactRequiresAttention, setContactRequiresAttention] =
    useState(false);
  const [deleteModal, setDeleteModal] = useState({
    opened: false,
    contactToDelete: {},
    errorDeleting: false,
  });

  const updateAndRenderNeedsAttention = (row, classes, attributes, style) => {
    style["font-weight"] = "350";
    style["font-size"] = "20px";
    style["text-overflow"] = "ellipsis";
    style["overflow"] = "hidden";
    style["max-width"] = "300px";

    if (row.needsAttention || contactNeedsAttention(row)) {
      style["background-color"] = "#FEF8F2";
      if (!contactRequiresAttention) {
        const enterActionId = window?.dtrum?.enterAction(
          `${contactRequiresAttentionMessage}`,
          undefined,
          new Date().getTime(),
          window.location.href
        );
        window?.dtrum?.leaveAction(enterActionId, new Date().getTime());

        setContactRequiresAttention(true);
      }
    }
  };

  const contactNeedsAttention = (row) => {
    row.needsAttention = row?.item?.cust_cntc_inf_vrfcn_cd?.some(
      (contactType) => contactNeedsAttentionStatuses[contactType]
    );
    return row.needsAttention;
  };

  const isVerifiedContact = (row) => {
    row.isVerifiedContact =
      row?.item?.cust_cntc_inf_vrfcn_cd?.some(
        (verifyContact) => verifiedContact[verifyContact]
      ) && row?.item?.cust_cntc_inf_vrfcn_cd.length === 1;
    return row.isVerifiedContact;
  };

  const handleDeleteContactClick = (row) => {
    setDeleteModal({
      ...deleteModal,
      opened: true,
      contactToDelete: row.item,
    });
  };

  const handleDeleteSubmit = async () => {
    setDeleteModal({
      ...deleteModal,
      errorDeleting: false,
    });

    try {
      setLoadSpinnerVisible(true);
      const response = await passthroughToContactCollection(
        "DELETE",
        `/api/v1/contact/${deleteModal.contactToDelete.bsi_cust_id}`,
        contactsContext.user.isExternal
      );

      const { data } = response;
      if (data.statusCode === 200 || data.statusCode === 201) {
        setContactRequiresAttention(false);
        setSuccessMessage("Contact has been deleted.");
        const newContacts = [...contactsContext.accountInfo.contacts];

        const newContactsIndexes = newContacts.map(
          (contact) => contact.bsi_cust_id
        );

        const indexToDelete = newContactsIndexes.indexOf(
          deleteModal.contactToDelete.bsi_cust_id
        );
        newContacts.splice(indexToDelete, 1);

        contactsContext.updateAccountInfo({
          ...contactsContext?.accountInfo,
          contacts: newContacts,
        });

        setDeleteModal({
          opened: false,
          contactToDelete: {},
          errorDeleting: false,
        });
      } else {
        setDeleteModal({
          opened: false,
          contactToDelete: {},
          errorDeleting: true,
        });
        const enterActionId = window?.dtrum?.enterAction(
          `${contactDeleteErrorMessage}`,
          undefined,
          new Date().getTime(),
          window.location.href
        );
        window?.dtrum?.leaveAction(enterActionId, new Date().getTime());
      }
      setLoadSpinnerVisible(false);
      return;
    } catch (err) {
      setDeleteModal({
        opened: false,
        contactToDelete: {},
        errorDeleting: true,
      });

      const enterActionId = window?.dtrum?.enterAction(
        `${contactDeleteErrorMessage}`,
        undefined,
        new Date().getTime(),
        window.location.href
      );
      window?.dtrum?.leaveAction(enterActionId, new Date().getTime());

      setLoadSpinnerVisible(false);
      return;
    }
  };

  const isNoPrimaryContact = (contacts) => {
    const cust_cntc_typ_cd = contacts
      .map((contact) => contact.cust_cntc_typ_cd)
      .flat();
    if (cust_cntc_typ_cd.includes("PC")) {
      return false;
    }

    const enterActionId = window?.dtrum?.enterAction(
      `${noPrimaryContactMessage}`,
      undefined,
      new Date().getTime(),
      window.location.href
    );
    window?.dtrum?.leaveAction(enterActionId, new Date().getTime());
    return true;
  };

  const handleEditContactClick = (row) => {
    contactsContext.updateActionStartTime(new Date().getTime());

    navigate(
      `/accounts/${contactsContext.accountInfo.acct_nbr}/contacts/edit`,
      { state: { contactId: row.item["bsi_cust_id"] } }
    );
  };

  const handleCreateContactClick = () => {
    contactsContext.updateActionStartTime(new Date().getTime());

    navigate(`/accounts/${contactsContext.accountInfo.acct_nbr}/contacts/add`);
  };

  return (
    <Suspense fallback={<TdsLoadingSpinner centered overlay />}>
      <Await resolve={loaderData.contacts} errorElement={<Home />}>
        {(loadedContacts) => {
          if (loadedContacts?.data?.body?.contacts) {
            return (
              <div className="search-results-page">
                {contactSuccessMessage && (
                  <TdsAlert
                    className="needs-attention-alert"
                    type="success"
                    dismissible={true}
                  >
                    {contactSuccessMessage}
                  </TdsAlert>
                )}
                {isNoPrimaryContact(loadedContacts?.data?.body?.contacts) && (
                  <TdsAlert
                    className="needs-attention-alert"
                    type="info"
                    dismissible={true}
                  >
                    {noPrimaryContactMessage}
                  </TdsAlert>
                )}
                {!contactsContext.user.is_read_only &&
                  contactRequiresAttention && (
                    <TdsAlert
                      className="needs-attention-alert"
                      type="error"
                      dismissible={true}
                    >
                      {contactRequiresAttentionMessage}
                    </TdsAlert>
                  )}
                {deleteModal.errorDeleting && (
                  <TdsAlert
                    className="needs-attention-alert"
                    type="error"
                    dismissible={true}
                  >
                    {contactDeleteErrorMessage}
                  </TdsAlert>
                )}
                <div className="search-results-account-copy">
                  <h1 className="tds-h1 search-results-title">
                    Contacts For Account Number{" "}
                    {loadedContacts?.data?.body?.acct_nbr}
                  </h1>
                  <h3 className="search-results-sub-title">
                    {loadedContacts?.data?.body?.acct_nm}
                  </h3>
                  {contactsContext.policyInfo && (
                    <h4
                      data-testid="search-results-policy-number"
                      className="search-results-sub-title"
                    >
                      Policy Number: <b>{contactsContext.policyInfo}</b>
                    </h4>
                  )}
                </div>
                <ContactsDataTable
                  readOnlyUser={contactsContext.user.is_read_only}
                  contacts={contactsContext.accountInfo.contacts}
                  updateAndRenderNeedsAttention={updateAndRenderNeedsAttention}
                  handleEditContactClick={handleEditContactClick}
                  handleCreateContactClick={handleCreateContactClick}
                  handleDeleteContactClick={handleDeleteContactClick}
                  contactNeedsAttention={contactNeedsAttention}
                  handleDeleteSubmit={handleDeleteSubmit}
                  isVerifiedContact={isVerifiedContact}
                />
                <TdsModal
                  opened={deleteModal.opened}
                  dialog-title="Delete Contact"
                  size="medium"
                >
                  <p>
                    Are you sure you want to delete this contact? You cannot
                    undo this action.
                  </p>
                  {/* TdsModal-buttons are added to the footer by setting slot="footer" */}
                  <TdsModalButtons slot="footer">
                    <TdsButton
                      variant="primary"
                      data-dismiss="modal"
                      onClick={handleDeleteSubmit}
                      data-testid="confirm-delete-button"
                    >
                      DELETE CONTACT
                    </TdsButton>
                    <TdsButton data-dismiss="modal">Cancel</TdsButton>
                  </TdsModalButtons>
                </TdsModal>
                {loadSpinnerVisible && (
                  <TdsLoadingSpinner
                    size="x-large"
                    label-displayed
                    overlay
                    centered
                  ></TdsLoadingSpinner>
                )}
              </div>
            );
          }
          return null;
        }}
      </Await>
    </Suspense>
  );
}

export default SearchResults;
